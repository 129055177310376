import React, { useEffect, useState } from 'react';
import './App.scss';
import { Modal, Layout, theme, Typography, Menu, Button } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined } from '@ant-design/icons';
import QuestionInput from './components/questionInput';
import Conversation from './components/conversation';
import LoginButton from './components/loginButton';
import LogoutButton from './components/logoutButton';
import {
  BadRequestError,
  Chat,
  deleteChat,
  getChat,
  getChats,
  getCurrentUser,
  ModerationError,
  renameChat,
  sendQuestion,
  ServerOverloadedError,
  User,
} from './api';
import Loading from './components/loading';
import Chats from './components/chats';
import { ItemType } from 'antd/es/menu/hooks/useItems';

const { Header, Content, Footer, Sider } = Layout;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [chats, setChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // get the current user from the api
  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const user = await getCurrentUser();
        if (user) {
          // we're now authenticated
          setIsAuthenticated(true);
          setUser(user);
          // load up the chats
          const chats = await getChats();
          // sort by descending date
          chats.sort((a, b) => (a.updatedOn > b.updatedOn ? -1 : 1));
          setChats(chats);
        } else {
          setIsAuthenticated(false);
          setUser(null);
          setChats([]);
        }
      } catch (e: any) {
        setIsAuthenticated(false);
        setUser(null);
        setChats([]);
      }
      setIsLoading(false);
    };

    fetchUser();
  }, []);

  const onDeleteChat = async (chatId: string) => {
    const success = await deleteChat(chatId);
    if (success) {
      // remove the deleted chat from the list
      setChats(chats.filter((chat) => chat.id !== chatId));
      // if the deleted chat was the selected chat, clear the selected chat
      if (selectedChat && selectedChat.id === chatId) {
        setSelectedChat(null);
      }
    }
    return success;
  };

  const onRenameChat = async (chatId: string, name: string) => {
    const success = await renameChat(chatId, name);
    if (success) {
      // update the chat name
      setChats(chats.map((chat) => (chat.id === chatId ? { ...chat, name } : chat)));
    }
    return success;
  };

  const onSelectChat = async (chat: Chat) => {
    setIsLoading(true);
    // need to get the full chat with all it's messages
    const chatWithMessages = await getChat(chat.id);
    if (chatWithMessages) {
      setSelectedChat(chatWithMessages);
    }
    setIsLoading(false);
  };

  const onSendQuestion = async (question: string) => {
    // send the question to the api and get a new version of the chat
    try {
      const chat = await sendQuestion(selectedChat?.id || 'new', question);
      if (chat) {
        // if there was no selected chat before, add this one to the list
        if (!selectedChat) {
          setChats([...chats, chat]);
          setSelectedChat(chat);
        } else {
          // update the selected chat
          setSelectedChat(chat);
        }
      }
      return true;
    } catch (error: any) {
      // check for moderation error
      if (error instanceof ModerationError) {
        Modal.error({
          title: 'Your question was rejected',
          content: (
            <>
              <div>Your question was rejected by the moderation system for the following reason(s)</div>
              <ul>
                {error.moderationErrors.map((reason: string) => (
                  <li key={reason}>{reason}</li>
                ))}
              </ul>
              <div>This may be an error in the moderation system, please try rephrasing your question.</div>
            </>
          ),
        });
      } else if (error instanceof BadRequestError) {
        Modal.error({
          title: 'Your question was rejected',
          content: error.message,
        });
      } else if (error instanceof ServerOverloadedError) {
        Modal.error({
          title: 'Server overloaded',
          content: 'We seem to be very busy right now - please try again later.',
        });
      } else {
        Modal.error({
          title: 'Error sending question',
          content: 'There was an error sending your question, please try again later.',
        });
      }
    }
    return false;
  };

  const onNewChat = async () => {
    setSelectedChat(null);
  };

  const items: ItemType[] = [
    {
      key: 'title',
      label: 'Cocktail Bot',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: <a href={`${process.env.REACT_APP_API_ENDPOINT}/logout`}>Logout</a>,
    },
  ];

  return (
    <>
      <Layout hasSider style={{ minHeight: '100vh', width: '100vw', overflowX: 'hidden' }}>
        <Sider
          collapsible
          trigger={null}
          collapsed={collapsed}
          collapsedWidth={0}
          width={300}
          breakpoint="sm"
          onCollapse={(value) => setCollapsed(value)}
        >
          <Chats
            chats={chats}
            selectedChat={selectedChat}
            onSelectChat={onSelectChat}
            onDeleteChat={onDeleteChat}
            onRenameChat={onRenameChat}
            onNewChat={onNewChat}
            onCloseChats={() => setCollapsed(true)}
            isBusy={isLoading}
          />
        </Sider>
        <Layout>
          <Header className="header" title="Cocktail Bot" style={{ padding: 0, background: colorBgContainer }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
              >
                History
              </Button>
              <Menu style={{ flex: 'auto' }} mode="horizontal" items={items} />
            </div>
          </Header>
          <Content style={{ width: '100vw' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}>
              <div style={{ width: '100%', maxWidth: 1000 }}>
                <Conversation chat={selectedChat} isLoading={isLoading || !isAuthenticated} picture={user?.picture} />
                <QuestionInput onSendQuestion={onSendQuestion} />
              </div>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Cocktail Bot ©2023</Footer>
        </Layout>
      </Layout>
      {isLoading && <Loading />}
      <Modal title="Login" open={!isLoading && !isAuthenticated} closable={false} footer={null}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: 20 }}>Welcome to the AI cocktail assistant. Please login to continue.</div>
          <LoginButton />
        </div>
      </Modal>
    </>
  );
};

export default App;
