import { Button, Input, Menu, MenuProps, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, MenuFoldOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Chat } from '../api';

const ChatRow = ({
  chat,
  isSelected,
  onClick,
  onDeleteChat,
  onRenameChat,
  disabled,
}: {
  chat: Chat;
  isSelected: boolean;
  onClick: () => void;
  onDeleteChat: () => Promise<boolean>;
  onRenameChat: (name: string) => Promise<boolean>;
  disabled: boolean;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditingModalVisible, setIsEditingModalVisible] = useState(false);
  const [newName, setNewName] = useState(chat.name);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const onDelete = async () => {
    setIsDeleting(true);
    await onDeleteChat();
    setIsDeleting(false);
  };

  const onShowDeleteConfirm = (e: React.SyntheticEvent) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this chat?',
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: onDelete,
    });
  };

  const onShowEditingModel = (e: React.SyntheticEvent) => {
    setIsEditingModalVisible(true);
    e.stopPropagation();
  };
  const onHideEditingModel = (e: React.SyntheticEvent) => {
    setIsEditingModalVisible(false);
    setErrorMessage(undefined);
    setNewName(chat.name);
  };

  const onUpdateChatName = async (e: React.SyntheticEvent) => {
    setIsUpdating(true);
    setErrorMessage(undefined);
    try {
      const success = await onRenameChat(newName);
      if (success) {
        setIsEditingModalVisible(false);
      }
    } catch (e: any) {
      console.error(e);
      setErrorMessage(e.message);
    }
    setIsUpdating(false);
  };

  const className = isSelected ? 'chat-row chat-row-selected' : 'chat-row';

  const onSelect = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <>
      <div
        className={className}
        onClick={() => onSelect()}
        key={chat.id}
        style={{
          fontWeight: isSelected ? 'bold' : 'normal',
          padding: 10,
          minHeight: 40,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: '1px solid rgba(255,255,255,0.1)',
        }}
      >
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div>{chat.name}</div>
          <div style={{ marginTop: 10 }}>
            <small>{chat.updatedOn.toLocaleString()}</small>
          </div>
        </div>
        {/* add buttons for the delete and edit and put them at the end of the flex box */}
        <Button
          danger
          shape="circle"
          ghost
          loading={isDeleting}
          disabled={isDeleting || disabled}
          type="primary"
          icon={<DeleteOutlined />}
          style={{ marginLeft: 10 }}
          onClick={onShowDeleteConfirm}
        />
        <Button
          shape="circle"
          ghost
          disabled={isDeleting || disabled}
          type="primary"
          icon={<EditOutlined />}
          style={{ marginLeft: 10 }}
          onClick={onShowEditingModel}
        />
      </div>
      <Modal
        title="Rename Chat"
        open={isEditingModalVisible}
        onOk={onUpdateChatName}
        onCancel={onHideEditingModel}
        confirmLoading={isUpdating}
        cancelButtonProps={{ disabled: isUpdating }}
      >
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        Rename "{chat.name}" to:
        <Input disabled={isUpdating} onChange={(e) => setNewName(e.target.value)} value={newName} />
      </Modal>
    </>
  );
};

const Chats = ({
  chats,
  selectedChat,
  onSelectChat,
  onDeleteChat,
  onRenameChat,
  onNewChat,
  onCloseChats,
  isBusy,
}: {
  chats: Chat[];
  selectedChat: Chat | null;
  onSelectChat: (chat: Chat) => void;
  onDeleteChat: (chatId: string) => Promise<boolean>;
  onRenameChat: (chatId: string, name: string) => Promise<boolean>;
  onNewChat: () => void;
  onCloseChats: () => void;
  isBusy: boolean;
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
        <Button disabled={isBusy} type="primary" style={{ flex: 1 }} onClick={onNewChat}>
          New Chat
        </Button>
        <Button
          color="white"
          type="text"
          style={{ color: 'white' }}
          icon={<MenuFoldOutlined color="white" />}
          onClick={onCloseChats}
        />
      </div>
      {chats.map((chat) => (
        <ChatRow
          disabled={isBusy}
          key={chat.id}
          chat={chat}
          isSelected={chat.id === selectedChat?.id}
          onClick={() => onSelectChat(chat)}
          onDeleteChat={() => onDeleteChat(chat.id)}
          onRenameChat={(name: string) => onRenameChat(chat.id, name)}
        />
      ))}
    </div>
  );
};

export default Chats;
