export type User = {
  name: string;
  picture: string | undefined | null;
};

export type ChatElement = {
  id: string;
  question: string;
  answer: string;
  date: string;
};

export type Chat = {
  id: string;
  name: string;
  createdOn: Date;
  updatedOn: Date;
  conversation: ChatElement[];
};

export class ApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ApiError';
  }
}

export class BadRequestError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'BadRequestError';
  }
}

export class NotAuthorisedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotAuthorisedError';
  }
}

export class NotFoundError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

export class ModerationError extends ApiError {
  constructor(message: string, public moderationErrors: string[]) {
    super(message);
    this.name = 'ModerationError';
  }
}

export class ServerOverloadedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'ServerOverloadedError';
  }
}

async function makeRequest(url: string, method: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE', body?: any): Promise<any> {
  const response = await fetch(url, {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  if (response.status === 204) {
    return {};
  }
  let json = null;
  try {
    json = await response.json();
  } catch (e) {
    if (response.status === 501 || response.status === 502 || response.status === 503 || response.status === 504) {
      throw new ServerOverloadedError('Server overloaded');
    }
    // didn't get json back - throw a generic error
    console.error(response.status, response.statusText);
    throw new ApiError('An error occurred');
  }
  if (!response.ok) {
    if (response.status === 400) {
      if (json.errorCode === 'moderation') {
        throw new ModerationError(json.message, json.moderationErrors);
      }
      throw new BadRequestError(json.message);
    }
    if (response.status === 401 || response.status === 403) {
      throw new NotAuthorisedError(json.message);
    }
    if (response.status === 404) {
      throw new NotFoundError(json.message);
    }
    if (
      response.status === 429 ||
      response.status === 501 ||
      response.status === 502 ||
      response.status === 503 ||
      response.status === 504
    ) {
      throw new ServerOverloadedError(json.message);
    }
    // no idea what the error is - throw a generic error
    throw new ApiError(json.message || 'An error occurred');
  }
  // no errors - response was ok
  return json;
}

// fetch the current user from the backend - make sure to send
export async function getCurrentUser(): Promise<User | null> {
  return await makeRequest(`${process.env.REACT_APP_API_ENDPOINT}/api/me`, 'GET');
}

// get an individual chat by id
export async function getChat(id: string): Promise<Chat | null> {
  const result = await makeRequest(`${process.env.REACT_APP_API_ENDPOINT}/api/me/chats/${id}`, 'GET');
  return {
    ...result.chat,
    date: new Date(result.chat.date),
    conversation: (result.chat.conversation || []).map((chatElement: ChatElement) => ({
      ...chatElement,
      date: new Date(chatElement.date),
    })),
  };
}

// get the chats for the current user
export async function getChats(): Promise<Chat[]> {
  const result = await makeRequest(`${process.env.REACT_APP_API_ENDPOINT}/api/me/chats`, 'GET');
  if (!result || !result.chats) {
    return [];
  }
  return result.chats.map((chat: Chat) => ({
    ...chat,
    createdOn: new Date(chat.createdOn),
    updatedOn: new Date(chat.updatedOn),
    conversation: (chat.conversation || []).map((chatElement: ChatElement) => ({
      ...chatElement,
      date: new Date(chatElement.date),
    })),
  }));
}

// delete a chat by chat id
export async function deleteChat(id: string): Promise<boolean> {
  try {
    await makeRequest(`${process.env.REACT_APP_API_ENDPOINT}/api/me/chats/${id}`, 'DELETE');
  } catch {
    return false;
  }
  return true;
}

// rename a chat by chat id
export async function renameChat(id: string, name: string): Promise<boolean> {
  const result = await makeRequest(`${process.env.REACT_APP_API_ENDPOINT}/api/me/chats/${id}`, 'PATCH', { name });
  return result.success;
}

// send a new question to the chat
export async function sendQuestion(id: string, question: string): Promise<Chat | null> {
  const result = await makeRequest(`${process.env.REACT_APP_API_ENDPOINT}/api/me/chats/${id}/questions`, 'POST', {
    question,
  });
  return {
    ...result.chat,
    date: new Date(result.chat.date),
    conversation: (result.chat.conversation || []).map((chatElement: ChatElement) => ({
      ...chatElement,
      date: new Date(chatElement.date),
    })),
  };
}
