import { Button } from 'antd';

const LoginButton = () => {
  return (
    <Button type="primary" href={`${process.env.REACT_APP_API_ENDPOINT}/login`}>
      Log In
    </Button>
  );
};

export default LoginButton;
