import React, { useState } from 'react';
import { SendOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

const { TextArea } = Input;

const QuestionInput = ({ onSendQuestion }: { onSendQuestion: (question: string) => Promise<boolean> }) => {
  const [question, setQuestion] = useState('');
  const [isSending, setIsSending] = useState(false);
  const sendQuestion = async () => {
    setIsSending(true);
    const success = await onSendQuestion(question);
    if (success) {
      setQuestion('');
    }
    setIsSending(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <TextArea
        style={{ padding: 20, margin: 20 }}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder="Ask me a question..."
        autoSize={{ minRows: 3, maxRows: 5 }}
        // detect enter key
        onPressEnter={sendQuestion}
        disabled={isSending}
      />
      {/* the send button */}
      <Button
        loading={isSending}
        disabled={isSending}
        shape="circle"
        size="large"
        icon={<SendOutlined />}
        onClick={sendQuestion}
        style={{ marginRight: 20 }}
      />
    </div>
  );
};

export default QuestionInput;
