import { UserOutlined } from '@ant-design/icons';
import aiImage from '../assets/images/ai.png';
import { Card, Skeleton, Typography } from 'antd';
import { Chat, ChatElement } from '../api';

const { Paragraph } = Typography;

const Message = ({ message }: { message: string }) => {
  // need to split the message by newlines and then render each line as a separate div
  const lines = message.split('\n');
  // filter any empty lines
  return (
    <>
      {lines.map((line) => (
        <div>{line}</div>
      ))}
    </>
  );
};

const Messages = ({ messages, picture }: { messages: ChatElement[]; picture: string | null | undefined }) => {
  return (
    <>
      {messages.map((message) => (
        <div key={message.id} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: 20,
              lineHeight: 2,
              borderBottom: '1px solid #E7E7E7',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {picture ? (
                <img alt="human" src={picture} style={{ width: 40, height: 40, marginRight: 20 }} />
              ) : (
                <UserOutlined style={{ fontSize: 40, marginRight: 20 }} />
              )}
              <div>
                <Paragraph
                  copyable={{ text: message.answer, format: 'text/plain' }}
                  style={{ fontFamily: 'Montserrat', fontSize: '14pt' }}
                >
                  <Message message={message.question} />
                </Paragraph>
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: 20,
              backgroundColor: 'white',
              lineHeight: 2,
              borderBottom: '1px solid #E7E7E7',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img alt="ai" src={aiImage} style={{ width: 40, height: 40, marginRight: 20 }} />
              <div style={{ fontFamily: 'Montserrat', fontSize: '14pt' }}>
                <Paragraph
                  copyable={{ text: message.answer, format: 'text/plain' }}
                  style={{ fontFamily: 'Montserrat', fontSize: '14pt' }}
                >
                  <Message message={message.answer} />
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const Conversation = ({
  chat,
  picture,
  isLoading,
}: {
  chat: Chat | null;
  picture: string | null | undefined;
  isLoading: boolean;
}) => {
  const messages = chat?.conversation || [];
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Card title={chat?.name || 'New Chat'}>
        I'm an AI assistant that specializes in alcoholic beverages. I can provide advice on drink menus, cocktail
        ingredients, how to make cocktails, wine parings, beers flavours and anything else related to alcoholic drinks.
        I can also give advice on marketing for restaurants and bars.
      </Card>
      {isLoading ? (
        <Skeleton avatar={{ shape: 'square', size: 40 }} active paragraph={{ rows: 4 }} />
      ) : (
        <Messages messages={messages} picture={picture} />
      )}
    </div>
  );
};

export default Conversation;
